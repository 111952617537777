import React from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';

function MembershipByMail({ showMail }) {
  const handlePrint = () => {
    // convert url to file
    var request = new XMLHttpRequest();
    request.open(
      'GET',
      'assets/Tahoe-Art-League-membership-application.pdf',
      true
    );
    request.responseType = 'blob';
    request.onload = function () {
      // convert file to obj
      const objFile = URL.createObjectURL(request.response);
      // open pdf
      const pdfWindow = window.open(objFile);
      // print pdf
      if (pdfWindow) pdfWindow.print();
    };
    request.send();
  };

  return (
    <React.Fragment>
      <div className="membership-form-col">
        <span style={{ fontSize: '1.1rem', fontWeight: 600, color: '#000' }}>
          Membership Application
        </span>
        <div className="input-wrap">
          <div>
            <Row>
              <Icon className="material-icons-round">visibility</Icon>
              <StyledLink
                href="https://talart.org/uploads/membership/Tahoe-Art-League-membership-application-2024.pdf"
                target="_blank"
                rel="noreferrer"
              >
                View Application
              </StyledLink>
            </Row>
            <Row>
              <Icon className="material-icons-round">file_download</Icon>
              <StyledLink
                download="Tahoe-Art-League-membership-application.pdf"
                href="https://talart.org/uploads/membership/Tahoe-Art-League-membership-application-2024.pdf"
                title="Membership Application"
              >
                Download Application
              </StyledLink>
            </Row>
            <Row>
              <Icon className="material-icons-round">print</Icon>
              <StyledLink onClick={handlePrint}>Print Application</StyledLink>
            </Row>
          </div>
          <div className="infoText" style={{ marginTop: '3em' }}>
            Please return the application to:
          </div>
          <address>
            Tahoe Art League
            <br />
            3062 Lake Tahoe Blvd
            <br />
            South Lake Tahoe, CA 96150
            {/* <br />
            <br />
            (530) 544-2313
            <br />
            info@talart.org */}
          </address>
        </div>
      </div>

      <Button
        onClick={() => showMail(false)}
        variant="contained"
        color="primary"
        style={{ borderRadius: '4px', marginTop: '1.5em' }}
      >
        &lt;&lt; Back
      </Button>
    </React.Fragment>
  );
}

export default MembershipByMail;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.7em;
  /* height: 50px; */
  /* border: 1px dotted red; */
`;

const Icon = styled.span`
  color: #00afdb;
  font-size: 1.2rem;
  margin-right: 0.4em;
  transition: 0.2s ease-in-out;

  ${Row}:hover & {
    opacity: 0.7;
  }
`;

const StyledLink = styled.a`
  color: #000;
  font-weight: 300;
  transition: 0.2s ease-in-out;

  ${Row}:hover & {
    color: #00afdb;
  }
`;
