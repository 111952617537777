import React, { Component } from 'react';
import Joi from 'joi-browser';
import * as memberService from '../services/memberService';
import { ToastContainer, toast } from 'react-toastify';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  TextField,
  Button,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import 'react-toastify/dist/ReactToastify.css';
import MemberProfile from './memberProfile';
import MailOrOnline from './mailOrOnline';

class Membership extends Component {
  state = {
    member: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      emailConfirm: '',
      membershipLevel: '',
      street: '',
      city: '',
      state: '',
      zip: '',
      website: '',
      artMedium: '',
      educationExperience: '',
      canHelpWith: [],
      newMember: '',
      hideProfileHint: true,
    },
    member2: {
      firstName2: '',
      lastName2: '',
      phone2: '',
      email2: '',
      emailConfirm2: '',
      membershipLevel2: '',
      street2: '',
      city2: '',
      state2: '',
      zip2: '',
      website2: '',
      artMedium2: '',
      educationExperience2: '',
      canHelpWith2: [],
      newMember2: '',
      hideProfileHint2: true,
    },
    membershipPrice: '',
    errors: {},
    errors2: {},
    showProfile: false,
    showMailOrOnline: true,
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    const showMailOrOnline = this.props.location.showMailOrOnline;

    if (typeof showMailOrOnline !== 'undefined')
      setTimeout(() => {
        this.setState({ showMailOrOnline });
      }, 100);

    setTimeout(() => {
      sessionStorage.setItem('formData', JSON.stringify(this.state));
    }, 200);

    if (JSON.parse(sessionStorage.getItem('formData')) !== null)
      this.setState(JSON.parse(sessionStorage.getItem('formData')));
  }

  theme = createMuiTheme({
    typography: {
      fontFamily: "'Nunito', sans-serif",
      fontSize: 11.5,
    },
    palette: {
      primary: {
        main: '#00CCFF',
      },
      secondary: {
        main: '#00CCFF',
      },
    },
  });

  schema = {
    firstName: Joi.string().max(50).trim().required().label('First Name'),
    lastName: Joi.string().max(50).trim().required().label('Last Name'),
    phone: Joi.string().trim().max(50).required().label('Phone'),
    email: Joi.string()
      .email()
      .required()
      .lowercase()
      .trim()
      .regex(/[.]([a-zA-Z]{2,})/)
      .label('Email'), // require at least 2chars after period
    emailConfirm: Joi.string()
      .email()
      .required()
      .lowercase()
      .trim()
      .regex(/[.]([a-zA-Z]{2,})/)
      .label('Email'), // require at least 2chars after period
    membershipActive: Joi.boolean(), // Needed if manually entered.    // TODO: take out ??
    membershipLevel: Joi.string()
      .valid('family', 'individual', 'associate', 'art patron', 'student')
      .required()
      .label('Membership'),
    street: Joi.string().trim().required().label('Street Address'),
    city: Joi.string().trim().required().label('City'),
    state: Joi.string().trim().required().label('State'),
    zip: Joi.string().trim().required().label('Zip'),
    website: Joi.string().allow('').max(50).lowercase().trim().label('Website'),
    artMedium: Joi.string().allow('').max(200).label('Art Medium'),
    educationExperience: Joi.string()
      .allow('')
      .max(200)
      .label('Education/Experience'),
    canHelpWith: Joi.label('I can help with'),
    newMember: Joi.boolean(),
    hideProfileHint: Joi.boolean(),
    // membershipPrice: Joi.number().required().max(150) // TODO: take out
  };

  schema2 = {
    firstName2: Joi.string().max(50).trim().required().label('First Name'),
    lastName2: Joi.string().max(50).trim().required().label('Last Name'),
    phone2: Joi.string().trim().max(50).required().label('Phone'),
    email2: Joi.string()
      .email()
      .required()
      .lowercase()
      .trim()
      .regex(/[.]([a-zA-Z]{2,})/)
      .label('Email'), // require at least 2chars after period
    emailConfirm2: Joi.string()
      .email()
      .required()
      .lowercase()
      .trim()
      .regex(/[.]([a-zA-Z]{2,})/)
      .label('Email'), // require at least 2chars after period
    membershipActive2: Joi.boolean(), // Needed if manually entered.  // TODO: take out ??
    membershipLevel2: Joi.string()
      .valid('family', 'individual', 'associate', 'art patron', 'student')
      .required()
      .label('Membership'),
    street2: Joi.string().trim().required().label('Street Address'),
    city2: Joi.string().trim().required().label('City'),
    state2: Joi.string().trim().required().label('State'),
    zip2: Joi.string().trim().required().label('Zip'),
    website2: Joi.string()
      .allow('')
      .max(50)
      .lowercase()
      .trim()
      .label('Website'),
    artMedium2: Joi.string().allow('').max(200).label('Art Medium'),
    educationExperience2: Joi.string()
      .allow('')
      .max(200)
      .label('Education/Experience'),
    canHelpWith2: Joi.label('I can help with'),
    newMember2: Joi.boolean(),
    hideProfileHint2: Joi.boolean(),
    // membershipPrice: Joi.number().required().max(150) // TODO: take out
  };

  validate = () => {
    const result = Joi.validate(this.state.member, this.schema, {
      abortEarly: false,
    });
    const result2 =
      this.state.member.membershipLevel === 'family'
        ? Joi.validate(this.state.member2, this.schema2, { abortEarly: false })
        : '';

    if (!result.error && !result2.error) return null;

    const errors = {};
    const errors2 = {};

    if (result.error) {
      for (let item of result.error.details) {
        errors[item.path[0]] = item.message;
        // custom error message for email
        if (item.context.key === 'email' || item.context.key === 'emailConfirm')
          errors[item.path[0]] = '"Email" must be a valid email';
      }
    }

    if (this.state.member.membershipLevel === 'family' && result2.error) {
      for (let item of result2.error.details) {
        errors2[item.path[0]] = item.message;
        // custom error message for email
        if (
          item.context.key === 'email2' ||
          item.context.key === 'emailConfirm2'
        )
          errors2[item.path[0]] = '"Email" must be a valid email';
      }
    }

    return { errors, errors2 };
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);

    // custom message for email
    if (
      Object.keys(obj)[0] === 'email' ||
      Object.keys(obj)[0] === 'emailConfirm'
    )
      return error
        ? (error.details[0].message = '"Email" must be a valid email')
        : null;

    return error ? error.details[0].message : null;
  };

  validateProperty2 = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema2[name] };
    const { error } = Joi.validate(obj, schema);

    // custom message for email
    if (
      Object.keys(obj)[0] === 'email2' ||
      Object.keys(obj)[0] === 'emailConfirm2'
    )
      return error
        ? (error.details[0].message = '"Email" must be a valid email')
        : null;

    return error ? error.details[0].message : null;
  };

  handleChange = ({ target: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const member = { ...this.state.member };
    member[input.name] = input.value;

    // TODO: replace rendering price via db. Also replace in the actual label further down.
    if (input.value === 'family') {
      this.setState({ membershipPrice: 70 });
      const member2 = { ...this.state.member2 };
      member2.membershipLevel2 = 'family';
      this.setState({ member2 });
    }
    if (input.value === 'individual') this.setState({ membershipPrice: 50 });
    if (input.value === 'associate') this.setState({ membershipPrice: 50 });
    if (input.value === 'art patron') this.setState({ membershipPrice: 50 });
    if (input.value === 'student') this.setState({ membershipPrice: 25 });

    // add or remove checkboxes to canHelpWith array
    // https://stackoverflow.com/questions/61986464/react-checkbox-if-checked-add-value-to-array
    if (input.name === 'canHelpWith') {
      let canHelpWith = [...this.state.member.canHelpWith, input.value]; // add item to array
      if (this.state.member.canHelpWith.includes(input.value))
        // if item in state-array
        canHelpWith = canHelpWith.filter((item) => item !== input.value); // remove item from array

      member[input.name] = canHelpWith;
    }

    this.setState({ member, errors });
  };

  handleChange2 = ({ target: input }) => {
    const errors2 = { ...this.state.errors2 };
    const errorMessage = this.validateProperty2(input);
    if (errorMessage) errors2[input.name] = errorMessage;
    else delete errors2[input.name];

    const member2 = { ...this.state.member2 };
    member2[input.name] = input.value;

    // TODO: replace rendering price via db
    if (input.value === 'family') this.setState({ membershipPrice: 70 });
    if (input.value === 'individual') this.setState({ membershipPrice: 50 });
    if (input.value === 'associate') this.setState({ membershipPrice: 50 });
    if (input.value === 'art patron') this.setState({ membershipPrice: 50 });
    if (input.value === 'student') this.setState({ membershipPrice: 25 });

    // add or remove checkboxes to canHelpWith array
    if (input.name === 'canHelpWith2') {
      let canHelpWith2 = [...this.state.member2.canHelpWith2, input.value];
      if (this.state.member2.canHelpWith2.includes(input.value))
        canHelpWith2 = canHelpWith2.filter((item) => item !== input.value);
      member2[input.name] = canHelpWith2;
    }

    this.setState({ member2, errors2 });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { errors, errors2 } = this.validate() || {}; // If validate returns null, use empty obj.

    this.setState({ errors: errors || {} });
    this.setState({ errors2: errors2 || {} });

    if (errors || errors2) return;

    if (this.state.member.email !== this.state.member.emailConfirm) {
      const errors = {};
      errors.emailConfirm = '"Email" has to be confirmed';
      this.setState({ errors });
      return;
    }

    if (this.state.member.membershipLevel === 'family') {
      if (this.state.member2.email2 !== this.state.member2.emailConfirm2) {
        const errors2 = {};
        errors2.emailConfirm2 = '"Email" has to be confirmed';
        this.setState({ errors2 });
        return;
      }

      if (this.state.member.email === this.state.member2.email2) {
        const errors = {};
        const errors2 = {};
        errors.email = 'Please enter a unique email for each member.';
        errors2.email2 = 'Please enter a unique email for each member.';
        this.setState({ errors, errors2 });
        return;
      }
    }

    // Call the server

    const getMemberByEmail = async (member) => {
      try {
        await memberService.getMemberByEmail(member);
        getMemberByNameAndEmail(member);
      } catch (ex) {
        // No email
        if (
          ex.response &&
          ex.response.status === 404 &&
          member.membershipLevel !== 'family'
        ) {
          // No email, one member
          // For 1st member, set hideProfileHint to true.
          const tempMember = { ...this.state.member };
          tempMember.hideProfileHint = true;
          this.setState({ member: tempMember });

          this.setState({ showProfile: true });
          setTimeout(() => {
            sessionStorage.setItem('formData', JSON.stringify(this.state));
          }, 100);
        }

        if (
          member.membershipLevel === 'family' &&
          member !== this.state.member2
        ) {
          // No email, two members, 1st member
          // For 1st member, set hideProfileHint to true.
          const tempMember = { ...this.state.member };
          tempMember.hideProfileHint = true;
          setTimeout(() => {
            // setTimeout needed, else state would not update, when going back and forth
            this.setState({ member: tempMember });
          }, 50);

          getMemberByEmail(this.state.member2);
        }

        if (
          ex.response &&
          ex.response.status === 404 &&
          member === this.state.member2
        ) {
          // No email, 2nd member

          // For 2nd member, set hideProfileHint2 to true.
          const tempMember2 = { ...this.state.member2 };
          tempMember2.hideProfileHint2 = true;
          this.setState({ member2: tempMember2 });

          this.setState({ showProfile: true });
          setTimeout(() => {
            sessionStorage.setItem('formData', JSON.stringify(this.state));
          }, 100);
        }
      }
    };
    getMemberByEmail(this.state.member);

    const getMemberByNameAndEmail = async (member) => {
      try {
        const response = await memberService.getMemberByNameAndEmail(member);

        if (!response.data[0].ableToRenew) {
          return toast.error(
            'Your membership is active until December 31st. Renewal opens October 1st.',
            {
              onClose: () => this.props.history.push('/'),
            }
          );
        }

        if (
          member.membershipLevel !== 'family' &&
          member.membershipLevel != null
        ) {
          // If only 1 member.
          // Set newMember and hideProfileHint to false.
          const tempMember = { ...this.state.member };
          tempMember.newMember = false;
          tempMember.hideProfileHint = false;
          this.setState({ member: tempMember });
        }

        if (
          member.email === this.state.member.email &&
          member.membershipLevel === 'family'
        ) {
          // If 1st member & 2 members.

          // Set newMember and hideProfileHint to false.
          const tempMember = { ...this.state.member };
          tempMember.newMember = false;
          tempMember.hideProfileHint = false;
          setTimeout(() => {
            // setTimeout needed, else state would not update
            this.setState({ member: tempMember });
          }, 50);

          // Call process over w/ 2nd member, if there's one.
          return getMemberByEmail(this.state.member2);
        }

        if (member.membershipLevel2 === 'family') {
          // If 2 members.
          // For 2nd member, set newMember2 and hideProfileHint2 to false.
          const tempMember2 = { ...this.state.member2 };
          tempMember2.newMember2 = false;
          tempMember2.hideProfileHint2 = false;
          this.setState({ member2: tempMember2 });
        }

        this.setState({ showProfile: true });
        setTimeout(() => {
          sessionStorage.setItem('formData', JSON.stringify(this.state));
        }, 100);
      } catch (ex) {
        if (ex.response && ex.response.status === 404) {
          return toast.error(
            'The information you entered does not match our records. Please correct your information. Contact member services if the problem persists.'
          );
        }
      }
    };
  };

  hideProfile = () => {
    this.setState({ showProfile: false });

    // TODO: do we need following 2 lines??
    const sessionData = this.state;
    sessionData.showProfile = false;
    setTimeout(() => {
      sessionStorage.setItem('formData', JSON.stringify(this.state));
    }, 100);
  };

  hideMailOrOnline = () => {
    this.setState({ showMailOrOnline: false });

    setTimeout(() => {
      sessionStorage.setItem('formData', JSON.stringify(this.state));
    }, 100);
  };

  render() {
    const { member, member2, errors, errors2 } = this.state;
    const familyMembership =
      this.state.member.membershipLevel === 'family' ||
      this.state.member2.membershipLevel === 'family';

    // Set error color for select input text.
    const muiErrorColor = errors.newMember ? '#f44336' : '';
    const muiErrorColor2 = errors2.newMember2 ? '#f44336' : '';

    return (
      <ThemeProvider theme={this.theme}>
        <section className="membership">
          <ToastContainer position="top-left" autoClose="15000" />
          <div className="margin-container-narrow membership-container">
            {this.state.showMailOrOnline && (
              <MailOrOnline hideMailOrOnline={this.hideMailOrOnline} />
            )}

            {!this.state.showProfile && !this.state.showMailOrOnline && (
              <form className="membership-form" onSubmit={this.handleSubmit}>
                <div className="membership-form-col">
                  <FormControl
                    error={!!errors.membershipLevel}
                    style={{ width: '100%' }}
                  >
                    <FormLabel
                      style={{
                        fontSize: '1.1rem',
                        fontWeight: 600,
                        color: '#000',
                      }}
                    >
                      Annual Membership Level
                    </FormLabel>
                    <div className="input-wrap">
                      <RadioGroup
                        name="membershipLevel"
                        value={
                          member.membershipLevel || member2.membershipLevel2
                        }
                        onChange={this.handleChange}
                      >
                        <FormControlLabel
                          value="family"
                          control={<Radio />}
                          label="$70 Family (active, voting) 2 people residing in same domicile"
                        />
                        <FormControlLabel
                          value="individual"
                          control={<Radio />}
                          label="$50 Individual (active, voting)"
                        />
                        <FormControlLabel
                          value="associate"
                          control={<Radio />}
                          label="$50 Associate (active, non-voting)"
                        />
                        <FormControlLabel
                          value="art patron"
                          control={<Radio />}
                          label="$50 Art Patron (not active, non-voting) support TAL"
                        />
                        <FormControlLabel
                          value="student"
                          control={<Radio />}
                          label="$25 Student (age 16-25, active, non-voting) currently enrolled in high school or college"
                        />
                      </RadioGroup>
                      <FormHelperText>{errors.membershipLevel}</FormHelperText>
                      <div>
                        <h4 style={{ marginTop: 30 }}>Note:</h4>
                        <p className="infoText" style={{ fontWeight: 300 }}>
                          All memberships renew December 31st. Memberships are
                          from Dec. 31st until Dec. 31st of the following year.
                        </p>
                        <p className="infoText" style={{ fontWeight: 300 }}>
                          For example, if you sign up for a new membership in
                          February, your renewal date is Dec. 31st.
                        </p>
                        <p className="infoText" style={{ fontWeight: 300 }}>
                          This just helps us simplify our bookkeeping.
                        </p>
                        <p className="infoText" style={{ fontWeight: 300 }}>
                          If you want an exception please contact us - for
                          example beginning your membership in late November or
                          December. We will happily accommodate you.
                        </p>
                      </div>
                    </div>
                  </FormControl>

                  <FormLabel
                    style={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      color: '#000',
                    }}
                  >
                    {!familyMembership && (
                      <React.Fragment>Member Details</React.Fragment>
                    )}
                    {familyMembership && (
                      <React.Fragment>First Member Details</React.Fragment>
                    )}
                  </FormLabel>
                  <div className="input-wrap input-wrap-less-top-padding">
                    {/* Add Select */}
                    <FormControl style={{ marginTop: '1.5em' }}>
                      <InputLabel
                        id="newReturning-label"
                        style={{ color: muiErrorColor }}
                      >
                        New or Returning Member
                      </InputLabel>
                      <Select
                        labelId="newReturning-label"
                        value={member.newMember}
                        name="newMember"
                        onChange={this.handleChange}
                        style={{ width: '200px' }}
                        // inputProps={{MenuProps: {disableScrollLock: true}}}
                        error={!!errors.newMember}
                      >
                        <MenuItem value={true}>New</MenuItem>
                        <MenuItem value={false}>Returning</MenuItem>
                      </Select>
                      {errors.newMember && (
                        <div
                          style={{
                            fontSize: '0.6160714285714286rem',
                            fontWeight: '400',
                            color: '#f44336',
                            marginTop: '3px',
                          }}
                        >
                          "New or Returning Member" must be one of [new,
                          returning]
                        </div>
                      )}
                    </FormControl>
                    <br />

                    <TextField
                      error={!!errors.firstName}
                      helperText={errors.firstName}
                      value={member.firstName}
                      onChange={this.handleChange}
                      id="firstName"
                      name="firstName"
                      type="text"
                      label="First Name"
                      style={{
                        marginTop: '1.5em',
                        marginRight: '2em',
                        width: 200,
                      }}
                    />
                    <TextField
                      error={!!errors.lastName}
                      helperText={errors.lastName}
                      value={member.lastName}
                      onChange={this.handleChange}
                      id="lastName"
                      name="lastName"
                      type="text"
                      label="Last Name"
                      style={{ marginTop: '1.5em', width: 200 }}
                    />
                    <br />

                    <TextField
                      error={!!errors.street}
                      helperText={errors.street}
                      value={member.street}
                      onChange={this.handleChange}
                      id="street"
                      name="street"
                      type="text"
                      label="Street Address"
                      style={{
                        marginTop: '1.5em',
                        marginRight: '2em',
                        width: 200,
                      }}
                    />
                    <TextField
                      error={!!errors.city}
                      helperText={errors.city}
                      value={member.city}
                      onChange={this.handleChange}
                      id="city"
                      name="city"
                      type="text"
                      label="City"
                      style={{ marginTop: '1.5em', width: 200 }}
                    />
                    <br />
                    <TextField
                      error={!!errors.state}
                      helperText={errors.state}
                      value={member.state}
                      onChange={this.handleChange}
                      id="state"
                      name="state"
                      type="text"
                      label="State"
                      style={{
                        marginTop: '1.5em',
                        marginRight: '2em',
                        width: 200,
                      }}
                    />
                    <TextField
                      error={!!errors.zip}
                      helperText={errors.zip}
                      value={member.zip}
                      onChange={this.handleChange}
                      id="zip"
                      name="zip"
                      type="text"
                      label="Zip"
                      style={{ marginTop: '1.5em', width: 200 }}
                    />

                    <TextField
                      error={!!errors.phone}
                      helperText={errors.phone}
                      value={member.phone}
                      onChange={this.handleChange}
                      id="phone"
                      name="phone"
                      type="text"
                      label="Phone"
                      style={{ marginTop: '1.5em', width: 200 }}
                    />
                    <br />
                    <TextField
                      error={!!errors.email}
                      helperText={errors.email}
                      value={member.email}
                      onChange={this.handleChange}
                      id="email"
                      name="email"
                      type="email"
                      label="Email"
                      style={{
                        marginTop: '1.5em',
                        marginRight: '2em',
                        width: 200,
                      }}
                    />
                    <TextField
                      error={!!errors.emailConfirm}
                      helperText={errors.emailConfirm}
                      value={member.emailConfirm}
                      onChange={this.handleChange}
                      id="emailConfirm"
                      name="emailConfirm"
                      type="emailConfirm"
                      label="Confirm Email"
                      style={{ marginTop: '1.5em', width: 200 }}
                    />
                    <br />
                  </div>

                  {familyMembership && (
                    <React.Fragment>
                      <FormLabel
                        style={{
                          fontSize: '1.1rem',
                          fontWeight: 600,
                          color: '#000',
                        }}
                      >
                        Second Member Details
                      </FormLabel>
                      <div className="input-wrap input-wrap-less-top-padding">
                        {/* Add Select */}
                        <FormControl style={{ marginTop: '1.5em' }}>
                          <InputLabel
                            id="newReturning-label"
                            style={{ color: muiErrorColor2 }}
                          >
                            New or Returning Member
                          </InputLabel>
                          <Select
                            labelId="newReturning-label"
                            value={member2.newMember2}
                            name="newMember2"
                            onChange={this.handleChange2}
                            style={{ width: '200px' }}
                            // inputProps={{MenuProps: {disableScrollLock: true}}}
                            error={!!errors2.newMember2}
                          >
                            <MenuItem value={true}>New</MenuItem>
                            <MenuItem value={false}>Returning</MenuItem>
                          </Select>
                          {errors2.newMember2 && (
                            <div
                              style={{
                                fontSize: '0.6160714285714286rem',
                                fontWeight: '400',
                                color: '#f44336',
                                marginTop: '3px',
                              }}
                            >
                              "New or Returning Member" must be one of [new,
                              returning]
                            </div>
                          )}
                        </FormControl>
                        <br />

                        <TextField
                          error={!!errors2.firstName2}
                          helperText={errors2.firstName2}
                          value={member2.firstName2}
                          onChange={this.handleChange2}
                          id="firstName2"
                          name="firstName2"
                          type="text"
                          label="First Name"
                          style={{
                            marginTop: '1.5em',
                            marginRight: '2em',
                            width: 200,
                          }}
                        />
                        <TextField
                          error={!!errors2.lastName2}
                          helperText={errors2.lastName2}
                          value={member2.lastName2}
                          onChange={this.handleChange2}
                          id="lastName2"
                          name="lastName2"
                          type="text"
                          label="Last Name"
                          style={{ marginTop: '1.5em', width: 200 }}
                        />
                        <br />

                        <TextField
                          error={!!errors2.street2}
                          helperText={errors2.street2}
                          value={member2.street2}
                          onChange={this.handleChange2}
                          id="street2"
                          name="street2"
                          type="text"
                          label="Street Address"
                          style={{
                            marginTop: '1.5em',
                            marginRight: '2em',
                            width: 200,
                          }}
                        />
                        <TextField
                          error={!!errors2.city2}
                          helperText={errors2.city2}
                          value={member2.city2}
                          onChange={this.handleChange2}
                          id="city2"
                          name="city2"
                          type="text"
                          label="City"
                          style={{ marginTop: '1.5em', width: 200 }}
                        />
                        <br />
                        <TextField
                          error={!!errors2.state2}
                          helperText={errors2.state2}
                          value={member2.state2}
                          onChange={this.handleChange2}
                          id="state2"
                          name="state2"
                          type="text"
                          label="State"
                          style={{
                            marginTop: '1.5em',
                            marginRight: '2em',
                            width: 200,
                          }}
                        />
                        <TextField
                          error={!!errors2.zip2}
                          helperText={errors2.zip2}
                          value={member2.zip2}
                          onChange={this.handleChange2}
                          id="zip2"
                          name="zip2"
                          type="text"
                          label="Zip"
                          style={{ marginTop: '1.5em', width: 200 }}
                        />

                        <TextField
                          error={!!errors2.phone2}
                          helperText={errors2.phone2}
                          value={member2.phone2}
                          onChange={this.handleChange2}
                          id="phone2"
                          name="phone2"
                          type="text"
                          label="Phone"
                          style={{ marginTop: '1.5em', width: 200 }}
                        />
                        <br />
                        <TextField
                          error={!!errors2.email2}
                          helperText={errors2.email2}
                          value={member2.email2}
                          onChange={this.handleChange2}
                          id="email2"
                          name="email2"
                          type="email"
                          label="Email"
                          style={{
                            marginTop: '1.5em',
                            marginRight: '2em',
                            width: 200,
                          }}
                        />
                        <TextField
                          error={!!errors2.emailConfirm2}
                          helperText={errors2.emailConfirm2}
                          value={member2.emailConfirm2}
                          onChange={this.handleChange2}
                          id="emailConfirm2"
                          name="emailConfirm2"
                          type="emailConfirm"
                          label="Confirm Email"
                          style={{ marginTop: '1.5em', width: 200 }}
                        />
                      </div>
                    </React.Fragment>
                  )}

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ borderRadius: '4px', margin: '1.5em auto' }}
                  >
                    Your Profile &gt;&gt;
                  </Button>
                </div>
              </form>
            )}

            {/* consider implementing this as separate component */}
            {/* { (true) && */}
            {this.state.showProfile && (
              <MemberProfile
                familyMembership={familyMembership}
                hideProfile={this.hideProfile}
                history={this.props.history}
                inputChange={this.handleChange}
                inputChange2={this.handleChange2}
                state={this.state}
              />
            )}
          </div>
        </section>
      </ThemeProvider>
    );
  }
}

export default Membership;
